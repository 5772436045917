import { Component, OnInit, Input } from '@angular/core';
import { LocalsettingsService } from '../services/localsettings.service';
import { PageScrollConfig } from 'ngx-page-scroll';
import { CustomTranslateService } from '../services/custom-translate.service';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { NgForm, FormControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { SEND_CONTACT_US } from './../constant/constant';


declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'app-heals',
  templateUrl: './heals.component.html',
  styleUrls: ['./heals.component.css']
})
export class HealsComponent implements OnInit {

  faChevronDown = faChevronDown;
  web:boolean = true;
  formCtrl = {
    name: '',
    email: '',
    content: '',
    channel: 'Website',
    field: '',
    type: 'Business',
    category: 'Heals'
  }

  radioform = new FormGroup({
    name: new FormControl,
    email: new FormControl,
    other: new FormControl,
    field: new FormControl('1')
  });
  sent:boolean = false;
  loading: boolean = false;

  constructor(private http: HttpClient, private translate: CustomTranslateService, private localStorage:LocalsettingsService) {
   }

  ngOnInit() {
    PageScrollConfig.defaultScrollOffset = 50;
    PageScrollConfig.defaultDuration = 800;
    // this.translate.setStyle();

    let getParams, url, param;
    url = window.location.search;
    param = this.parse_query_string(url + ' ');
    getParams = (param['?mobile'] || param['mobile']);
    if(!!getParams) {
      this.web = false;
    }
  }

  changeLang () {
    this.translate.changeLang();
    this.closeNav();
  }

  closeNav() {
    $('.navbar-collapse').collapse('hide');
  }

  async submitContent() {
    if(this.radioform.controls.name.valid && this.radioform.controls.email.valid){
      this.formCtrl.field = this.radioform.controls['field'].value;
      try {
        this.loading = true;
        await this.http
        .post( SEND_CONTACT_US , this.formCtrl)
        .toPromise();
        this.formCtrl = {
          name: '',
          email: '',
          content: '',
          channel: 'Website',
          field: '',
          type: 'Business',
          category: 'Heals'
        };
        this.radioform.reset();
        this.sent = true;
      } catch(err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  }

  // closeNav() {
  //   $('.navbar-collapse').collapse('hide');
  // }

  parse_query_string(str) {
    var vars = str.split("&");
    var query_string = {};
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      var key = decodeURIComponent(pair[0]);
      var value = decodeURIComponent(pair[1]);
      // If first entry with this name
      if (typeof query_string[key] === "undefined") {
        query_string[key] = decodeURIComponent(value);
        // If second entry with this name
      } else if (typeof query_string[key] === "string") {
        var arr = [query_string[key], decodeURIComponent(value)];
        query_string[key] = arr;
        // If third or later entry with this name
      } else {
        query_string[key].push(decodeURIComponent(value));
      }
    }
    return query_string;
  }

}
