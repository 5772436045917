import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalsettingsService } from './services/localsettings.service';
import { CustomTranslateService } from './services/custom-translate.service';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  constructor(private translate: TranslateService, private storeLang:LocalsettingsService, private custTranslate: CustomTranslateService, private route: ActivatedRoute) {
  }

  ngOnInit(){
    let lang, url, param;
    url = window.location.search;
    param = this.parse_query_string(url);
    lang = (param['?lang'] || param['lang']);

    const showDbiz = localStorage.getItem("showDbiz");
    if(!showDbiz){
      localStorage.setItem("showDbiz","true");
    }
    if(lang) {
      if( lang === 'zh-Hant' || lang === 'zh-Hans')
          this.translate.use('zh');
        else
          this.translate.use('en');
        this.storeLang.setLanguage( this.translate.currentLang);
    } else {
      if(this.storeLang.getLanguage() === "") {
        this.translate.setDefaultLang('en');
        this.translate.getBrowserLang() === 'zh' ? this.translate.use('zh') : this.translate.use('en');
        this.storeLang.setLanguage(this.translate.currentLang);
      } else {
        this.translate.use(this.storeLang.getLanguage());
      }
    }
  }

  parse_query_string(str) {
    var vars = str.split("&");
    var query_string = {};
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      var key = decodeURIComponent(pair[0]);
      var value = decodeURIComponent(pair[1]);
      // If first entry with this name
      if (typeof query_string[key] === "undefined") {
        query_string[key] = decodeURIComponent(value);
        // If second entry with this name
      } else if (typeof query_string[key] === "string") {
        var arr = [query_string[key], decodeURIComponent(value)];
        query_string[key] = arr;
        // If third or later entry with this name
      } else {
        query_string[key].push(decodeURIComponent(value));
      }
    }
    return query_string;
  }
}

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
