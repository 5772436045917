import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalsettingsService {

  constructor() { 
    console.log(localStorage);
  }
  
  getLanguage():string{
    if (localStorage){
      return localStorage['language'] || "";
    } else {
      return "";
    }
  }

  setLanguage(language: string){
    if (localStorage){
      localStorage['language'] = language;
    }
  }

  getSlider():boolean{
    if (localStorage){
      return localStorage['slider'] || false;
    } else {
      return false;
    }
  }

  setSlider(){
    if (localStorage){
      localStorage['slider'] = true;
    }
  }
}
