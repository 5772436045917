import { Component, OnInit, ViewEncapsulation, HostListener} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CLINIC_PATH, EC_PATH, CHECK_DOCTOR_INSURER } from '../constant/constant';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ConnectionService } from 'ng-connection-service';


@Component({
  selector: 'app-benefit',
  templateUrl: './benefit.component.html',
  styleUrls: ['./benefit.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BenefitComponent implements OnInit {

  doctor_clinic:any[];
  clinicId: string;
  loading: boolean = true;
  insurer = [];
  selectedDoctor = null;
  selectedInsurer = 'MAN0';
  hkid = '';
  DOB = '';
  next;
  agreeTerms = false;
  choosed;
  errorMsg = [];
  submitted = false;
  enableCheckBox = false;
  hkidValidated = false;
  isConnected;


  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient, private connectionService: ConnectionService) {
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.errorMsg = [];
      }
      else {
        this.errorMsg = [];
        this.errorMsg.push("Sorry. We are unable to process your request now. Please submit your request later. <br/>對不起。我們現在未能處理閣下之申請。請在稍後時間再遞交申請。");
      }
    })
  }

  ngOnInit() {
    // this.route.data.subscribe(data => (
    //     this.doctor_clinic = data['doctor_clinic']
    // ));
    if(!this.doctor_clinic) {
      this.route.queryParams.subscribe(async params => {
        this.clinicId = params.clinic_id;
        if(this.clinicId)
          await this.getCLinic();
        else 
          this.router.navigate(['/']);
        console.log(this.doctor_clinic)
        if(this.doctor_clinic.length > 0) {
          this.selectedDoctor = this.doctor_clinic[0]['doctor']['id'];
          this.checkDoctor();
        }
      });
    }
  }

  async getCLinic() {
    try {
      this.loading = true;
      this.doctor_clinic = await this.http
        .get(CLINIC_PATH ,{
          params: { clinic_id: this.clinicId }
        })
        .pipe(map((res: any) => res.doctor_clinics))
        .toPromise();
    } catch(err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  getInsurer() {
    this.next = true;
  }

  previous() { 
    this.next = false;
    this.errorMsg = [];
    this.submitted = false;
    this.agreeTerms = false;
    this.enableCheckBox = false;
    this.hkidValidated = false;
    this.hkid = "";
    this.DOB = "";
  }

  async result() {
    this.errorMsg = [];
    this.submitted = true;
    this.hkid = this.hkid.toUpperCase().trim();
    let hkid = this.hkid;
    if (this.hkid.charAt(this.hkid.length-3) != '(' && this.hkid.charAt(this.hkid.length-1) != ')') {
      hkid = this.insert(hkid, '(', hkid.length-1);
      hkid = this.insert(hkid, ')', hkid.length);
      // this.hkid = this.hkid.substring(0, this.hkid.length - 3) + this.hkid.charAt(this.hkid.length -2);
    }
    this.hkidValidated = this.validateHkid(hkid);
    if(this.DOB && this.validateHkid(hkid)) {
        try {
          let result = await this.ec(hkid);
          let route = this.router.config.find(r => r.path === 'web-portal');
          route = route.children.find(r => r.path === 'result');
          route.data = { 
            'ec_result': result.body,
            'hkid': hkid,
            'dob': this.DOB,
            'doctor': this.doctor_clinic[this.doctor_clinic.findIndex(x => x.doctor.id === this.selectedDoctor)].doctor.name,
            'status': result.status,
            'clinicId': this.clinicId
          };
          this.router.navigate(['/web-portal/result'], {queryParams: {clinic_id: this.clinicId}})
      } catch(err) {
        console.log(err);
        this.errorMsg = err.error.error_messages;
      }
    }
  }

  insert(main_string, ins_string, pos) {
    if(typeof(pos) == "undefined") {
      pos = 0;
    }
    if(typeof(ins_string) == "undefined") {
      ins_string = '';
    }
    return main_string.slice(0, pos) + ins_string + main_string.slice(pos);
  }

  async ec(hkid) {
    try {
      this.loading = true;
      return await this.http
        .post(EC_PATH ,{
            id_num: hkid,
            dob: this.DOB,
            insurer_id: this.selectedInsurer,
            clinic_id: this.clinicId
        }, {observe: 'response'})
        .pipe(map((res: any) => res))
        .toPromise();
    } catch(err) {
      console.log(err);
      throw err;
    } finally {
      this.loading = false;
    }
  }

  async checkDoctor() {
    try {
      this.loading = true;
      this.insurer = await this.http
        .get(CHECK_DOCTOR_INSURER , { params: {
          doctor_id: this.selectedDoctor ,
          clinic_id: this.clinicId
        }})
        .pipe(map((res: any) => res))
        .toPromise();
    } catch(err) {
      console.log(err);
      throw err;
    } finally {
      this.loading = false;
    }
  }


  // @HostListener('scroll', ['$event'])
  //   onScroll(event: any) {
  //       // visible height + pixel scrolled >= total height 
  //       if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
  //         this.enableCheckBox = true;
  //       }
  //   }

  validateHkid(hkid:string) {
    var strValidChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"

    //remove bracket for checking
    let reHkid = hkid.substring(0, hkid.length - 3) + hkid.charAt(hkid.length -2);
    
    //check if validated HKID format
    let resultArr = reHkid.match(/^([A-Z]{1,2})([0-9]{6})([A0-9])$/);
    
    if(resultArr) {
      //check if the bracket digit is correct
      let charPart = resultArr[1];
      let numPart:any = resultArr[2];
      let checkDigit = resultArr[3];

      let checkSum = 0;
      if (charPart.length == 2) {
          checkSum += 9 * (10 + strValidChars.indexOf(charPart.charAt(0)));
          checkSum += 8 * (10 + strValidChars.indexOf(charPart.charAt(1)));
      } else {
          checkSum += 9 * 36;
          checkSum += 8 * (10 + strValidChars.indexOf(charPart));
      }
      // calculate the checksum for numeric part
      for (var i = 0, j = 7; i < numPart.length; i++, j--)
          checkSum += j * numPart.charAt(i);
  
      // verify the check digit
      let remaining = checkSum % 11;
      let verify = remaining == 0 ? 0 : 11 - remaining;
  
      return verify.toString() == checkDigit.toString() || (verify == 10 && checkDigit == 'A');
    } else
      return false;
  }


}
