import { Component, OnInit, Input, HostListener, ViewChild } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocalsettingsService } from '../services/localsettings.service';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  query
} from '@angular/animations';
import { PageScrollConfig } from 'ngx-page-scroll';
import { FeaturesBoxComponent } from '../features-box/features-box.component';
import { CustomTranslateService } from '../services/custom-translate.service';
import { FormGroup, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ANDROID_LINK, IOS_LINK, SEND_CONTACT_US, SEND_NEWLETTER } from './../constant/constant';
import { Router } from '@angular/router';

declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'app-healsapp',
  templateUrl: './healsapp.component.html',
  styleUrls: ['./healsapp.component.css'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({opacity: 1})),

      state('out',style({opacity: 0, display: 'none'})),

      // fade in when created. this could also be written as transition('void => *')
      transition('in => out', [
        animate('1s')
      ])
    ]),
    // the fade-in/fade-out animation.
    trigger('firstSectionAni', [

      transition('* => goAnimate', [
        // hide the inner elements
        query('#app-info h1, #app-info p, #app-info button', style({ transform: 'translateY(20%)', opacity: 0 })),

        // animate the inner elements in, one by one
        query('#app-info h1',     animate(800, style({ transform: 'translateY(0%)', opacity: 1 }))),
        query('#app-info p',      animate(800, style({ transform: 'translateY(0%)', opacity: 1 }))),
        query('#app-info button', animate(800, style({ transform: 'translateY(0%)', opacity: 1 }))),
      ])
    ])
  ]
})
export class HealsappComponent implements OnInit {

  notScroll = true;
  @Input('isScroll') className: string;
  fadeOutAni: string;
  fadeInAni: string;
  sliderHasShown: boolean;
  @ViewChild(FeaturesBoxComponent) featuresBoxComponent: FeaturesBoxComponent;
  androidLink = ANDROID_LINK;
  iosLink = IOS_LINK;

  formCtrl = {
    name: '',
    email: '',
    content: '',
    channel: 'Website',
    field: '',
    type: 'Business',
    category: 'Heals'
  }

  radioform = new FormGroup({
    name: new FormControl,
    email: new FormControl,
    other: new FormControl,
    field: new FormControl('1')
  });
  sent:boolean = false;

  newsemail: string;
  newsSent: boolean = false;

  loading: boolean = false;

  showDbiz:boolean = false;

  constructor(private http: HttpClient, private translate: CustomTranslateService, private localStorage: LocalsettingsService,private router:Router) { }

  ngOnInit() {
    PageScrollConfig.defaultDuration = 1000;
    this.fadeOutAni = 'in';
    this.localStorage.getSlider() ? document.querySelector('body').classList.remove('lock-scroll-view') : document.querySelector('body').classList.add('lock-scroll-view');
    // if(this.storeSeen.getSlider()){
    this.sliderHasShown = this.localStorage.getSlider();
    this.notScroll = !this.localStorage.getSlider();
      // this.isScrolled = this.storeSeen.getSlider();
    // }
    // this.translate.setStyle();
    this.sliderHasShown ? null :this.fadeInAni = '';

    $('.navbar-nav>li>a, .navbar-nav>li>button').on('click', function(){
      $('.navbar-collapse').collapse('hide');
    });
    this.showDbiz=localStorage.getItem("showDbiz")==='true'?true:false;
  }

  ngAfterViewInit() {
    if(window.innerWidth < 992) {
      PageScrollConfig.defaultScrollOffset = 50;
    } else {
      PageScrollConfig.defaultScrollOffset = 0;
    }

    $('#subDialog').modal('show');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event){
    console.log(event);
    if(event.target.innerWidth < 992)
      PageScrollConfig.defaultScrollOffset = 50;
    else
      PageScrollConfig.defaultScrollOffset = 0;
  }

  changeLang () {
    this.translate.changeLang();
  }

  changeScroll() {
    console.log("can scroll");
    this.notScroll = false;
    console.log(this.notScroll);
    this.fadeOutAni = 'out';
    this.localStorage.setSlider();
    document.querySelector('body').classList.remove('lock-scroll-view');
    // this.checkView = false;
    setTimeout( () => {$('#subDialog').modal('show')}, 10000);
    setTimeout( () => { this.fadeInAni = 'goAnimate'; });
  }

  // disableScrollify() {
  //   $.scrollify.disable();
  //   console.log("disable");
  // }

  // enableScrollify() {
  //   setTimeout(() => {
  //     $.scrollify.enable();
  //     console.log("enable");
  //   }, 500)
  // }

  async submitContent() {
    if(this.radioform.controls.name.valid && this.radioform.controls.email.valid){
      this.formCtrl.field = this.radioform.controls['field'].value;
      try {
        this.loading = true;
        await this.http
        .post( SEND_CONTACT_US, this.formCtrl)
        .toPromise();
        this.formCtrl = {
          name: '',
          email: '',
          content: '',
          channel: 'Website',
          field: '',
          type: 'Business',
          category: 'Heals'
        };
        this.radioform.reset();
        this.sent = true;
      } catch(err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  }

  async newsletterBtn() {
    console.log(this.newsemail);
    if(this.newsemail != '' && this.validateEmail(this.newsemail)) {
      try {
        this.loading = true;
        await this.http
        .post( SEND_NEWLETTER, {
          email: this.newsemail
        })
        .toPromise();
        this.newsemail = '';
        // this.radioform.reset();
        this.newsSent = true;
      } catch(err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  toDbiz(){
    this.showDbiz = false;
    // localStorage.setItem('showDbiz','false');
    this.router.navigateByUrl('/dbiz');
  }
  closeDbiz(){
    this.showDbiz = false;
  }
}
