import { Component, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '../services/modal.service';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})

export class ModalComponent implements OnInit, OnDestroy {
    @Input() id: string;
    element: any;
    data: any = {};

    constructor(private modalService: ModalService, private el: ElementRef, private router: Router) {
        this.element = el.nativeElement;
    }

    ngOnInit() {
        if (!this.id) {
            console.error('modal must have an id');
            return;
        }
        // move element to bottom of page (just before </body>) so it can be displayed above everything else
        document.body.appendChild(this.element);

        // close modal on background click
        // let modal = this;
        // this.element.addEventListener('click', function (e: any) {
        //     if (e.target.className === 'modal') {
        //         modal.close();
        //     }
        // });
        this.modalService.add(this);
    }

    // remove self from modal service when directive is destroyed
    ngOnDestroy() {
        this.modalService.remove(this.id);
        this.element.remove();
    }

    closeModal(id: string) {
        this.modalService.close(id);
        if (this.data.redirect) {
            this.redirect(this.data.url);
        }         
    }

    open(data: Object) {
        this.data = data;
        this.element.style.display = 'block';
        document.body.classList.add('modal-open');
    }

    close() {
        this.element.style.display = 'none';
        document.body.classList.remove('modal-open');
    }

    redirect(path: string = '') {
        this.router.navigateByUrl(path);
    }
}