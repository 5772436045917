import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-hkexpress-buy',
  templateUrl: './hkexpress-buy.component.html',
  styleUrls: ['./hkexpress-buy.component.scss']
})
export class HkexpressBuyComponent implements OnInit {

  lang: string = 'en';
  form: FormGroup;
  inviteCode: string;
  countryCodes: string[] = ['60'];

  getCodeTime: number = 0; // 验证码获取时间
  getCodeInterval: any; // 验证码倒计时

  passwordType: string = 'password';
  confirmPasswordType: string = 'password';

  passwordTypeImg: string = 'assets/covid-19-testing/encryption.png';
  confirmPasswordTypeImg: string = 'assets/covid-19-testing/encryption.png';

  toastMsg: string = '';
  toastSuccessMsg: string = '';

  getCodeLoading: boolean = false;
  signUpLoading: boolean = false;

  dateName = '';
  dateValue = new Date();

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private translate: TranslateService,
    private http: HttpClient,
  ) {}

  ngOnInit() {
    this.lang = this.translate.currentLang;

    this.form = this.formBuilder.group({
      promo_code: [
        null, [
          Validators.required,
        ]
      ],
      name: [
        null, [
          Validators.required,
        ]
      ],
      phone: [
        null, [
          Validators.required,
          Validators.pattern('[0-9]*'),
        ]
      ],
      email: [
        null, [
          Validators.required,
          Validators.email,
        ]
      ],
      panel: [
        null, [
          Validators.required,
        ]
      ],
      district: [
        null, [
          Validators.required,
        ]
      ],
      clinic: [
        null, [
          Validators.required,
        ]
      ],
      date: [
        null, [
          Validators.required,
        ]
      ],
      time: [
        null, [
          Validators.required,
        ]
      ],
    });
  }

  ngOnDestroy(): void {

  }

  currentDateFormat(date, format: string = 'yyyy-mm-dd HH:MM'): any {
    const pad = (n: number): string => (n < 10 ? `0${n}` : n.toString());
    return format
      .replace('yyyy', date.getFullYear())
      .replace('mm', pad(date.getMonth() + 1))
      .replace('dd', pad(date.getDate()))
      .replace('HH', pad(date.getHours()))
      .replace('MM', pad(date.getMinutes()))
      .replace('ss', pad(date.getSeconds()));
  }

  onOk(result: Date) {
    this.dateName = this.currentDateFormat(result, 'yyyy-mm-dd');
    this.dateValue = result;
    this.form.get('date').setValue(result);
  }

  formatIt(date: Date, form: string) {
    const pad = (n: number) => (n < 10 ? `0${n}` : n);
    const dateStr = `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
    const timeStr = `${pad(date.getHours())}:${pad(date.getMinutes())}`;
    if (form === 'YYYY-MM-DD') {
      return dateStr;
    }
    if (form === 'HH:mm') {
      return timeStr;
    }
    return `${dateStr} ${timeStr}`;
  }

  toast(msg: string) {
    console.log(msg);
    this.toastMsg = msg;
    setTimeout(() => {
      this.toastMsg = '';
    }, 3000);
  }

  toastSuccess(msg: string) {
    this.toastSuccessMsg = msg;
    setTimeout(() => {
      this.toastSuccessMsg = '';
    }, 3000);
  }

  buy(){
    console.log(this.form.value);
  }

}
