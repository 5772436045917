import { Component, OnInit } from '@angular/core';
import { CustomTranslateService } from '../services/custom-translate.service';

@Component({
  selector: 'privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(private translate: CustomTranslateService) { }

  ngOnInit() {
    this.translate.setStyle(); 
   }
  
   changeLang () {
    this.translate.changeLang();
  }
  

}
