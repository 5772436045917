import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { IOS_LINK, ANDROID_LINK, GET_PENDING_VOUCHERS, CLINIC_PATH } from '../constant/constant';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ActionComponent implements OnInit {

  androidLink = ANDROID_LINK;
  iosLink = IOS_LINK;
  loading: boolean = false;
  clinicId: string;
  clinic: any;
  private translatedText: any;

  constructor(private translate: TranslateService, private route: ActivatedRoute, private http: HttpClient, private router: Router) {
    translate.use('en'); 
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.clinicId = params.clinic_id;
      var clinicQrCode = sessionStorage.getItem('clinicQrCode');
      if (clinicQrCode) {
        this.clinicId = clinicQrCode;
      } else {
        sessionStorage.setItem('clinicQrCode', this.clinicId);
      }
      if(!this.clinicId)
        this.router.navigate(['/']);
    });

    this.translate.get([
      'validation_message_empty_hkid',
      'validation_message_empty_voucher',
    ]).subscribe(res => this.translatedText = res);

    if(this.clinicId)
      this.getCLinic();
  }

  async getCLinic() {
    // try {
    //   this.loading = true;
    //   this.clinic = await this.http
    //     .get(CLINIC_PATH ,{
    //       params: { clinic_id: this.clinicId }
    //     })
    //     .pipe(map((res: any) => res.doctor_clinics))
    //     .toPromise();
    // } catch(err) {
    //   console.log(err);
    // } finally {
    //   this.loading = false;
    // }
    try {
      this.loading = true;
      this.clinic = await this.http
        .get(GET_PENDING_VOUCHERS , { 
          params : {'qr_code': this.clinicId }
        })
        .toPromise();
        this.updateClinicId();
    } catch(err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  updateClinicId() {
    this.clinicId = this.clinic.clinic[0].id;
  }

  goToCheckBenefit() {
    let route = this.router.config.find(r => r.path === 'web-portal');
    route = route.children.find(r => r.path === 'check-benefit');
    route.data = { 'doctor_clinic': this.clinic };
    this.router.navigate(['web-portal/check-benefit'], {queryParams: {clinic_id: this.clinicId}});
  }

  goToConsentPa() {
    let route = this.router.config.find(r => r.path === 'web-portal');
    route = route.children.find(r => r.path === 'consent-pa');
    route.data = { 'doctor_clinic': this.clinic };
      
    this.router.navigate(['web-portal/consent-pa'], {queryParams: {clinic_id: this.clinicId}});
  }

  goToVoucher() {
    let route = this.router.config.find(r => r.path === 'web-portal');
    route = route.children.find(r => r.path === 'vouchers');
    route.data = { 'doctor_clinic': this.clinic };
      
    this.router.navigate(['web-portal/vouchers'], {queryParams: {clinic_id: this.clinicId}});
  }

}
