import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { SlideshowModule } from 'ng-simple-slideshow';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SignaturePadModule } from 'angular2-signaturepad';

import { AppComponent, SafePipe } from './app.component';
import { HealsappComponent } from './healsapp/healsapp.component';
import { AppRoutingModule } from './app-routing.module';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { TermsComponent } from './terms/terms.component';
import { HealsComponent } from './heals/heals.component';
import { FeaturesBoxComponent } from './features-box/features-box.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SliderComponent } from './slider/slider.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { TermsMComponent } from './terms-m/terms-m.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ClaimComponent } from './claim/claim.component';
import { VoucherComponent } from './voucher/voucher.component';
import { VoucherListComponent } from './voucher-list/voucher-list.component';
import { ModalComponent } from './modal/modal.component';
import { HomeComponent } from './home/home.component';
import { BenefitComponent } from './benefit/benefit.component';
import { PreapprovalComponent } from './preapproval/preapproval.component';
import { ActionComponent } from './action/action.component';
import { SignatureFieldComponent } from './signature-field/signature-field.component';
import { ResultComponent } from './result/result.component';
import { HkexpressBuyComponent } from './hkexpress/hkexpress-buy/hkexpress-buy.component';
import { HkexpressQrcodeComponent } from './hkexpress/hkexpress-qrcode/hkexpress-qrcode.component';
import { HkexpressConfirmComponent } from './hkexpress/hkexpress-confirm/hkexpress-confirm.component';
import { HkexpressReportComponent } from './hkexpress/hkexpress-report/hkexpress-report.component';

import { QRCodeModule } from 'angularx-qrcode';
import { DbizComponent } from './dbiz/dbiz.component';
import { NgbDate, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { NgZorroAntdMobileModule } from 'ng-zorro-antd-mobile';

@NgModule({
  declarations: [
    AppComponent,
    HealsappComponent,
    HealsComponent,
    AppFooterComponent,
    TermsComponent,
    FeaturesBoxComponent,
    SliderComponent,
    TermsConditionComponent,
    TermsMComponent,
    ContactUsComponent,
    PrivacyPolicyComponent,
    ClaimComponent,
    VoucherComponent,
    VoucherListComponent,
    ModalComponent,
    SafePipe,
    HomeComponent,
    BenefitComponent,
    PreapprovalComponent,
    ActionComponent,
    SignatureFieldComponent,
    ResultComponent,
    HkexpressBuyComponent,
    HkexpressQrcodeComponent,
    HkexpressConfirmComponent,
    HkexpressReportComponent,
    DbizComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPageScrollModule,
    BrowserAnimationsModule,
    SlideshowModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SignaturePadModule,
    QRCodeModule,
    NgZorroAntdMobileModule,
    NgbModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/translations/', '.json');
}
