import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-preapproval',
  templateUrl: './preapproval.component.html',
  styleUrls: ['./preapproval.component.css']
})
export class PreapprovalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
