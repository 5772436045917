import { Component, OnInit } from '@angular/core';
import { CustomTranslateService } from '../services/custom-translate.service'
import { LocalsettingsService } from '../services/localsettings.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  constructor(private translate: CustomTranslateService, private localStorage:LocalsettingsService) {
  }

 ngOnInit() {
  this.translate.setStyle(); 
 }

 changeLang () {
  this.translate.changeLang();
}

}
