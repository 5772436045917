import { Component, OnInit, ViewChild} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-features-box',
  templateUrl: './features-box.component.html',
  styleUrls: ['./features-box.component.css']
})
export class FeaturesBoxComponent implements OnInit {

  imageUrls;
  viewSecond: number  = 4000;
  @ViewChild('slideshow') slideshow: any;

  constructor(private translate: TranslateService) { }

  ngOnInit() {
    
  }

  // ngDoCheck(){
  //   console.log(this.slideshow.slideIndex);
  //   switch (this.slideshow.slideIndex) {
  //     case 0: 
  //       document.querySelector('#feature').className = 'feature1';
  //     break;
  //     case 1:
  //       document.querySelector('#feature').className = 'feature2';
  //     break;
  //     case 2:
  //       document.querySelector('#feature').className = 'feature3';
  //     break;
  //     case 3:
  //       document.querySelector('#feature').className = 'feature4';
  //     break;
  //     case 4:
  //       document.querySelector('#feature').className = 'feature5';
  //     break;
  //     case 5:
  //       document.querySelector('#feature').className = 'feature6';
  //     break;
  //   }
    
  // hideFeatureBox() {
  //   document.querySelector('#feature2').classList.add('hideFeatures');
  //   document.querySelector('#feature3').classList.add('hideFeatures');
  //   document.querySelector('#feature4').classList.add('hideFeatures');
  //   document.querySelector('#feature5').classList.add('hideFeatures');
  //   document.querySelector('#feature6').classList.add('hideFeatures');
  // }

  // showFeatureBox() {
  //   document.querySelector('#feature2').classList.remove('hideFeatures');
  //   document.querySelector('#feature3').classList.remove('hideFeatures');
  //   document.querySelector('#feature4').classList.remove('hideFeatures');
  //   document.querySelector('#feature5').classList.remove('hideFeatures');
  //   document.querySelector('#feature6').classList.remove('hideFeatures');
  // }
}
