import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { CONFIRM_CLAIM } from './../constant/constant';
import { ModalService } from '../services/modal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.css']
})

export class VoucherComponent implements OnInit {
    confirmSuccessMessage: String;
    confirmErrorMessage: String;
    confirmVisit: boolean = false;
    hkid: String;
    loading: boolean = false;
    translatedText: Array<String>;
    voucher: Object;

    constructor(private http: HttpClient, translate: TranslateService, private route: ActivatedRoute, private router: Router) {
        translate.use('en');
        translate.get([
            'voucherClaimSuccess'
        ]).subscribe(res => this.translatedText = res);
    }

    ngOnInit() {
        this.route
            .data
            .subscribe(data => (
                this.voucher = data['voucher'],
                this.hkid = data['hkid']
            ));
        if (!this.voucher) {
            this.redirect();
        }
    }

    parseStringItem(item: Object) {
        if (!item)
            return null;
        return item['name'].split(', ');
    }

    // openModal(id: string, data: Object) {
    //     this.modalService.open(id, data);
    // }

    redirect() {
        this.router.navigateByUrl('');
    }

    async confirmClaim(transactionId: String, invoiceId: String) { 
        this.confirmErrorMessage = "";
        try {
            this.loading = true;
            let data = {
                'hkid': this.hkid.toUpperCase(),
                'invoice_id': invoiceId,
                'transaction_id': transactionId
            };
            await this.http
                .post(CONFIRM_CLAIM, data)
                .toPromise();
            
            this.confirmSuccessMessage = this.translatedText['voucherClaimSuccess'];
            this.confirmVisit = true;
        } catch(err) {
            console.log(err);
            this.confirmErrorMessage = err.error.error_messages;
        } finally {
            this.loading = false;
            // let modalId = 'voucher';
            // let modelData = {
            //     redirect: true,
            //     message: message
            // };
            //this.openModal(modalId, modelData);
        }
    }
}