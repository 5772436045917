import { Component, OnInit, QueryList, ElementRef, ViewChildren  } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SignatureFieldComponent } from '../signature-field/signature-field.component';
import { Router, ActivatedRoute } from '@angular/router';
import { CONSENT_PA_PATH } from '../constant/constant';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css']
})
export class ResultComponent implements OnInit {

  resultSign;
  form: FormGroup;
  result;
  confirmSuccessMessage;
  confirmErrorMessage;
  success;
  confirmVisit;
  hkid = '';
  DOB = '';
  loading = false;
  selectedEc;
  doctor;
  status;
  clinicId;

  // @ViewChildren('sigContainer1') public sigContainer1: QueryList<ElementRef>;
  // @ViewChildren (SignatureFieldComponent) public sigs: QueryList<SignatureFieldComponent>;

  constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private http: HttpClient) { }

  ngOnInit() {
    // this.form = this.fb.group({
    //   signatureField: ['', Validators.required],
    // });

    this.route.data.subscribe(data => {
      this.status = data['status'];
      console.log(this.status);
      this.result = data['ec_result'];
      this.doctor = data['doctor'];
      this.clinicId = data['clinicId'];
      console.log(this.result)
      if(!this.result) {
        this.route.queryParams.subscribe(async params => {
          this.clinicId = params.clinic_id;
          if(this.clinicId) {
            this.router.navigate(['/web-portal'], {queryParams: {clinic_id: this.clinicId}});
          }
          else
            this.router.navigate(['/']);
        });
      }
      this.hkid = data['hkid']; 
      this.DOB = data['dob']; 
    });
  }

  // public ngAfterViewInit() {
  //   this.beResponsive();
  // }

  // public clear() {
  //   console.log(this.sigs.first.signature);
  //   this.sigs.first.clear();
  // }


  // beResponsive() {s
  //   console.log('Resizing signature pad canvas to suit container size');
  //   this.size(this.sigContainer1.first, this.sigs.first);
  // }

  // size(container: ElementRef, sig: SignatureFieldComponent) {
  //   sig.signaturePad.set('canvasWidth', container.nativeElement.clientWidth);
  //   sig.signaturePad.set('canvasHeight', container.nativeElement.clientHeight );
  // }

  back() {
    this.confirmVisit = false;
  }

  returnHome() {
    if(this.clinicId)
      this.router.navigate(['/web-portal'], {queryParams: {clinic_id: this.clinicId}});
    else
      this.router.navigate(['/']);
  }
  
  async submit() {
    this.confirmVisit = true;
    this.success = false;
    try {
      this.loading = true;
      let policynum =  this.result['policies'][this.result['policies'].findIndex( x => x.ecid === this.selectedEc)]['polNo'];
      let policyName =  this.result['policies'][this.result['policies'].findIndex( x => x.ecid === this.selectedEc)]['planDesc'];
      console.log(this.clinicId)
      await this.http
        .post(CONSENT_PA_PATH ,{
            id_num: this.hkid ,
            dob: this.DOB,
            insurer_id: 'MAN0',
            policy_no: policynum,
            pa_reference_no: this.result.pa_reference_no,
            ec_reference_no: this.selectedEc,
            pa_token: this.result['pa_token'],
            plan_name: policyName,
            clinic_id: this.clinicId
        })
        .pipe(map((res: any) => res))
        .toPromise();
      this.success = true;
    } catch(err) {
      console.log(err);
      this.success = false;
    } finally {
      this.loading = false;
    }
  }

}
