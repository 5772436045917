import { Component, OnInit } from '@angular/core';
import { CustomTranslateService } from '../services/custom-translate.service';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { LocalsettingsService } from '../services/localsettings.service';
import { SEND_CONTACT_US } from '../constant/constant';

declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'app-dbiz',
  templateUrl: './dbiz.component.html',
  styleUrls: ['./dbiz.component.css']
})
export class DbizComponent implements OnInit {

  faChevronDown = faChevronDown;
  web:boolean = true;
  formCtrl = {
    name: '',
    email: '',
    content: '',
    channel: 'Website',
    field: '',
    type: 'Business',
    category: 'Heals'
  }

  radioform = new FormGroup({
    name: new FormControl,
    email: new FormControl,
    other: new FormControl,
    field: new FormControl('1')
  });
  sent:boolean = false;
  loading: boolean = false;
  
  constructor(
    private http: HttpClient, 
    private translate: CustomTranslateService, 
    private localStorage:LocalsettingsService,
  ) { }

  ngOnInit() {
  }

  changeLang () {
    this.translate.changeLang();
    this.closeNav();
  }

  closeNav() {
    $('.navbar-collapse').collapse('hide');
  }

  async submitContent() {
    if(this.radioform.controls.name.valid && this.radioform.controls.email.valid){
      this.formCtrl.field = this.radioform.controls['field'].value;
      try {
        this.loading = true;
        await this.http
        .post( SEND_CONTACT_US , this.formCtrl)
        .toPromise();
        this.formCtrl = {
          name: '',
          email: '',
          content: '',
          channel: 'Website',
          field: '',
          type: 'Business',
          category: 'Heals'
        };
        this.radioform.reset();
        this.sent = true;
      } catch(err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  }

}
