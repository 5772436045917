import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { ANDROID_LINK, IOS_LINK, GET_PENDING_VOUCHERS, GET_VOUCHER_DETAIL } from './../constant/constant';

@Component({
  selector: 'app-voucher-list',
  templateUrl: './voucher-list.component.html',
  styleUrls: ['./voucher-list.component.css']
})
export class VoucherListComponent implements OnInit {
  androidLink = ANDROID_LINK;
  iosLink = IOS_LINK;
  voucherList: any;
  loading: boolean = false;
  clinicId: string;
  voucherListForm: FormGroup;
  errorMsg: Array<String>;
  private translatedText: any;

  constructor(private fb: FormBuilder, private http: HttpClient, private translate: TranslateService, private route: ActivatedRoute, private router: Router) {
    translate.use('en');
    this.voucherListForm = this.fb.group({
      hkid: this.fb.control('', Validators.required),
      // voucher: this.fb.control('', Validators.required)
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.clinicId = params.clinic_id;
    });

    this.translate.get([
      'validation_message_empty_hkid',
      'validation_message_empty_voucher',
    ]).subscribe(res => this.translatedText = res);


    this.route.data.subscribe(data => (
      this.voucherList = data['doctor_clinic']
    ));
    if(!this.voucherList) {
      this.route.queryParams.subscribe(params => {
        this.clinicId = params.clinic_id;
        if(this.clinicId)
          this.getVouchers();
        else 
          this.router.navigate(['/web-portal'], {queryParams: {clinic_id: this.clinicId}})
      });
    }
  }

  async getVouchers() {
    try {
      this.loading = true;
      this.voucherList = await this.http
        .get(GET_PENDING_VOUCHERS , {
          params: {'qr_code': this.clinicId }
        })
      .toPromise();
      if(this.voucherList['vouchers'] === 0)
        this.router.navigate(['/web-portal'], {queryParams: {clinic_id: this.clinicId}})
    } catch(err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  async getVoucherDetail() {
    try {
      this.loading = true;
      this.errorMsg = [];
      let validated = true;
      let controls = this.voucherListForm.controls;

      for (let [key, value] of Object.entries(controls)) {  
        if (value.errors && value.errors.required) {
          validated = false;
          switch (key) {
            case 'hkid':
              this.errorMsg.push(this.translatedText['validation_message_empty_hkid']);
              break;
            // case 'voucher':
            //   this.errorMsg.push(this.translatedText['validation_message_empty_voucher']);
            //   break;
            default:
              // this.errorMsg.push(key + ' is required.');
          }
        }
      };
      if (!validated)
        throw new Error('Validation Error');
      
      // let data = JSON.parse(controls.voucher.value);
      let data = {
        'id_num' : controls.hkid.value.toUpperCase(),
        'clinic_id' : this.clinicId
      };
      
      let res = await this.http
        .post(GET_VOUCHER_DETAIL , data)
        .toPromise();

      let route = this.router.config.find(r => r.path === 'web-portal');
      route = route.children.find(r => r.path === 'voucher')
      route.data = { 'voucher': res, 'hkid': controls.hkid.value };
      this.router.navigateByUrl('web-portal/voucher');
    } catch(err) {
      console.log(err)
      this.errorMsg.push('Sorry. We couldn’t find your pending voucher, please re-try it later. Thanks')
      // if (err.status === 400)
        // this.errorMsg.push(err.error.error_messages);
    } finally {
      this.loading = false;
    }
  }
}
