import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-hkexpress-qrcode',
  templateUrl: './hkexpress-qrcode.component.html',
  styleUrls: ['./hkexpress-qrcode.component.css']
})
export class HkexpressQrcodeComponent implements OnInit {

  data:string = 'kjhjkhk';
  routerSub: Subscription;


  
  constructor(
    private router: Router,
    private routerParams: ActivatedRoute
  ) { }

  ngOnInit() {
    const name = this.routerParams.snapshot.queryParamMap.get('name');
    const tel = this.routerParams.snapshot.queryParamMap.get('tel');
    const email = this.routerParams.snapshot.queryParamMap.get('email');
    const clinic = this.routerParams.snapshot.queryParamMap.get('clinicName');
    console.log('name ',name);
  }

}
