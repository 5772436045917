import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalsettingsService } from './localsettings.service';

declare var jquery:any;
declare var $ :any;

@Injectable({
  providedIn: 'root'
})
export class CustomTranslateService {

  constructor(private translate: TranslateService, private localStorage:LocalsettingsService) {
  }
  
  changeLang () {
    if(this.translate.currentLang === 'en'){
      this.translate.use('zh');
      this.localStorage.setLanguage('zh');
      this.zhStyle();
    } else {
      this.translate.use('en');
      this.localStorage.setLanguage('en');
      this.enStyle();
    }
  }

  setStyle() {
    if(this.translate.currentLang === 'zh'){
      this.zhStyle();
    } else {
      this.enStyle();    
    }
  }

  zhStyle() {
    // $('p, a, .reason-content, button').not($('#footer p, .footer-small a, #btn-skip a, .email-link')).css('font-size', '20px');
    // $('h1').css('font-size', '42px');
    // $('.nav-item>a, .nav-item>button').not($('.btn-lang')).css('font-size', '16px');
    // $($('.btn-lang')).css('font-size', '14px');
    // $('#footer p').css('font-size', '16px');
    // $('.footer-small').css('font-size', '13px'); 
  }

  enStyle() {
    // $('p, a, .reason-content, button').not($('#footer p, .footer-small a, #btn-skip a, .email-link')).css('font-size', '16px');
    // $('h1').css('font-size', '36px');
    // $('.nav-item>a, .nav-item>button').not($('.btn-lang')).css('font-size', '14px');
    // $($('.btn-lang')).css('font-size', '18px');
    // $('#footer p').css('font-size', '14px'); 
    // $('.footer-small').css('font-size', '11px'); 
  }

}
