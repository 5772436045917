import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HealsappComponent } from './healsapp/healsapp.component';
import { HealsComponent } from './heals/heals.component'
import { TermsComponent } from './terms/terms.component';
import { TermsMComponent } from './terms-m/terms-m.component'
import { ContactUsComponent } from './contact-us/contact-us.component'
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ClaimComponent } from './claim/claim.component';
import { VoucherComponent } from './voucher/voucher.component';
import { VoucherListComponent } from './voucher-list/voucher-list.component';
import { HomeComponent } from './home/home.component';
import { BenefitComponent } from './benefit/benefit.component';
import { PreapprovalComponent } from './preapproval/preapproval.component';
import { ActionComponent } from './action/action.component';
import { ResultComponent } from './result/result.component';
import { HkexpressBuyComponent } from './hkexpress/hkexpress-buy/hkexpress-buy.component';
import { HkexpressQrcodeComponent } from './hkexpress/hkexpress-qrcode/hkexpress-qrcode.component';
import { HkexpressConfirmComponent } from './hkexpress/hkexpress-confirm/hkexpress-confirm.component';
import { HkexpressReportComponent } from './hkexpress/hkexpress-report/hkexpress-report.component';
import { DbizComponent } from './dbiz/dbiz.component';

const routes: Routes = [
  { 
    path: '', 
    component: HealsappComponent,
  }, { 
    path: 'company', 
    component: HealsComponent,
  }, { 
    path: 'terms', 
    component: TermsComponent,
  }, {
    path: 'm/terms',
    component: TermsMComponent,
  }, {
    path: 'm/contact-us',
    component: ContactUsComponent,
  }, {
    path: 'privacy',
    component: PrivacyPolicyComponent,
  }, {
    path: 'claim',
    component: ClaimComponent,
  }, {
    path: 'dbiz',
    component: DbizComponent,
  },{
    path: 'web-portal',
    component: HomeComponent,
    children: [{
      path: '',
      component: ActionComponent,
    }, {
      path: 'check-benefit',
      component: BenefitComponent,
    }, {
      path: 'result',
      component: ResultComponent,
    }, {
      path: 'voucher',
      component: VoucherComponent,
    }, {
      path: 'vouchers',
      component: VoucherListComponent,
    }]
  }, {
    path: 'hkexpress',
    children: [{
      path: '',
      component: HkexpressBuyComponent,
    }, {
      path: 'buy',
      component: HkexpressBuyComponent,
    }, {
      path: 'qrcode',
      component: HkexpressQrcodeComponent,
    }, {
      path: 'confirm',
      component: HkexpressConfirmComponent,
    }, {
      path: 'report',
      component: HkexpressReportComponent,
    }]
  }, { 
    path: '**', 
    redirectTo: ''
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
