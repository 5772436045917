import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { LocalsettingsService } from '../services/localsettings.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})

export class SliderComponent implements OnInit {
  
  imageUrls;
  viewSecond: number  = 5500;
  @ViewChild('slideshow') slideshow: any;
  @Output() finished = new EventEmitter<boolean>();

  constructor(private translate: TranslateService, private storeSeen:LocalsettingsService) { }

  ngOnInit() {
    this.translate.get([
      'caption1',
      'caption2',
      'caption3'
    ]).subscribe((res: string) => {
      // console.log(res);
      // this.caption1 = res;
      // console.log(this.caption1);

      this.imageUrls =  [
        { url: '../../assets/slide1.png', caption: res['caption1'] },
        { url: '../../assets/slide2.png', caption: res['caption2'] },
        { url: '../../assets/slide3.png', caption: res['caption3'] }
      ];

      if(!this.storeSeen.getSlider()) {
        this.showSliderSuccess();
      }
    });
    // this.translate.get('caption2').subscribe((res: string) => {
    //   console.log(res);
    //   this.caption2 = res;
    // });
    // this.translate.get('caption3').subscribe((res: string) => {
    //   console.log(res);
    //   this.caption3 = res;
    // });
    // console.log(this.translate.use);
    console.log(this.slideshow);
  }

  showSliderSuccess() {
    console.log('Success');
    setTimeout(() => {
      console.log("timeout " + this.viewSecond*this.imageUrls.length);
      this.finished.emit();
    }, this.viewSecond*this.imageUrls.length);
  }

  skipFunction() {
    this.finished.emit();
  }

}