import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.css']
})
export class AppFooterComponent implements OnInit{

  @Input() footText: String;
  @Input() taglineText: String;
  @Input() link: String;

  currentYear = new Date().getFullYear();

  constructor() { 
  }

  ngOnInit() {
    if(!this.link)
    this.link = "";
  }


}
