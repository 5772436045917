import { environment } from '../../environments/environment';

export const ENV = environment.ENV;
export const API_VERSION = environment.API_VERSION;

export const URL = environment.URL;
export const API_URL = environment.API_URL;
// export const API_URL = `http://192.168.10.10/api/v1`;

export const SEND_CONTACT_US = `${API_URL}/crm/ContactUs`;
export const SEND_NEWLETTER = `${API_URL}/crm/newsletter`;

export const CLINIC_PATH = `${API_URL}/doctor_clinic`;
export const GET_PENDING_VOUCHERS = `${API_URL}/transactions/vouchers`;
export const GET_VOUCHER_DETAIL = `${API_URL}/transactions/voucher_detail`;
export const CONFIRM_CLAIM = `${API_URL}/transactions/voucher_claim`;

export const CHECK_DOCTOR_INSURER = `${API_URL}/preapproval/ec/insurer`;
export const EC_PATH = `${API_URL}/preapproval/ec`;
export const CONSENT_PA_PATH = `${API_URL}/preapproval/patient/consent`;

export const ANDROID_LINK = 'https://play.google.com/store/apps/details?id=com.healshealthcare.patientapp';
export const IOS_LINK = 'https://itunes.apple.com/hk/app/heals-patient-app/id1442752092?mt=8';
